import React from 'react';
import { useSelector } from 'react-redux';
import Certificados       from './situacionAgente/Situacion';

export default function () {

  return (
    <>
      <ul className="nav nav-tabs mt-2" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button 
            className="nav-link active" 
            id="home-tab" 
            data-bs-toggle="tab" 
            data-bs-target="#situaciones" 
            type="button" 
            role="tab" 
            aria-controls="situaciones" 
            aria-selected="true"
          >
            Certificados
          </button>
        </li>
        {/*
        <li className="nav-item" role="presentation">
          <button 
            className="nav-link" 
            id="profile-tab" 
            data-bs-toggle="tab" 
            data-bs-target="#suplementos" 
            type="button" 
            role="tab" 
            aria-controls="suplementos" 
            aria-selected="false"
          >
            Licencias
          </button>
        </li>    
        */}
      </ul>

      <div className="tab-content" id="myTabContent">
        
        <div className="tab-pane fade show active" id="situaciones" role="tabpanel" aria-labelledby="home-tab">
          <Certificados/>
        </div>

        <div className="tab-pane fade" id="suplementos" role="tabpanel" aria-labelledby="profile-tab">
          {/*<Certificados/>*/}    
        </div>

    

      </div>

    </>
  )
}

import React, {useState} from 'react';
import {redirect,useNavigate} from 'react-router-dom';
import md5 from 'md5';
import { useSelector, useDispatch } from 'react-redux';
import * as dispsLogin from '../store/slices/login';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Body from '../components/Body';
import Cookies from 'universal-cookie';
import { urlApi } from '../utils/api';
const axios = require('axios');
const cookies = new Cookies(); 


export default function Login(){

    const {login,msj} = useSelector((state) => state.login);
    const dispatch = useDispatch();
    const [loading,setLoading]=useState(false);    
    let navigate = useNavigate();
    const handleLogin = ({usuario,password})=>{ 

        setLoading(true);

        axios({
            method: 'post',
            url: urlApi+'/rrhh/login',
            data: {"username" : usuario,"password" : md5(password)}
        })
        .then(function (res) {
            if(res.data[0].Bloqueado===1){
                dispatch(dispsLogin.set_msj("El usuario está bloqueado."));
            }else if(res.data.length>0){
                cookies.set('dataDni',res.data[0].Dni,{path:'/'});
                cookies.set('dataLegajo',res.data[0].Legajo,{path:'/'});
                cookies.set('dataApellido',res.data[0].Apellido,{path:'/'});
                cookies.set('dataNombre',res.data[0].Nombre,{path:'/'});
                cookies.set('dataPver',res.data[0].PermisoVer,{path:'/'});
                cookies.set('dataPeditar',res.data[0].PermisoEditar,{path:'/'});
                cookies.set('dataPaltas',res.data[0].PermisoAltas,{path:'/'});   
                dispatch(dispsLogin.set_msj(''));
                //dispatch(dispsLogin.set_login(true))
                setLoading(false);
                return navigate("/body");
            }else{
                dispatch(dispsLogin.set_msj('Error en los datos de usuario o contraseña'));
                setLoading(false);
            }
        })
        .catch(function (error) {
            dispatch(dispsLogin.set_msj('Error en los datos de usuario o contraseña'));
            setLoading(false);
        });

    }

    //if(login){
        //return(<Body/>);
    //}else{
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md"></div>    
                        <div className="col-md-4 text-center border-bottom border-top p-3">
                            <Formik
                                initialValues={{
                                    usuario:'',
                                    password:''
                                }}            
                                validate={(valores) => {
                                    let errores = {};
                                    if(!valores.usuario){errores.usuario = 'Por favor ingresa un usuario'}
                                    if(!valores.password){errores.password = 'Por favor ingresa una contraseña'}        
                                    return errores;
                                }}        
                                onSubmit={(valores, {resetForm}) => {
                                    resetForm();                                
                                    handleLogin(valores);    
                                }}
                            >
                                {({ errors })=>(

                                    <Form>
                                        <div className="form-group p-1">
                                            <label htmlFor="inputLegajo" style={{'fontSize':14}}>Usuario</label>
                                            <Field 
                                                className="form-control form-control-sm"     
                                                type="text"
                                                name="usuario"
                                                id="usuario" 
                                                placeholder="usuario"
                                            />
                                            <ErrorMessage name="usuario" component={() => (<div style={{color:'red'}} >{errors.usuario}</div>)} />    
                                        </div>

                                        <div className="form-group p-1">
                                            <label htmlFor="inputApellido" style={{'fontSize':14}}>Contraseña</label>
                                            <Field 
                                                className="form-control form-control-sm"
                                                type="password"
                                                name="password" 
                                                id="password" 
                                                placeholder="contraseña"
                                            />
                                            <ErrorMessage name="password" component={() => (<div style={{color:'red'}} >{errors.password}</div>)} />
                                        </div>
                                                
                                        {
                                            loading 
                                            ?  
                                                <div className="spinner-border text-info" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>      
                                            :  
                                                <div style={{'marginTop':15,color:'red'}}> <p>{msj}</p> </div>       
                                        }

                                        <div className="mt-5">
                                            <button
                                                type="submit"
                                                className="btn btn-sm shadow mb-2 rounded" 
                                                style={{'backgroundColor':'#F3CDA9','width':'10rem'}}
                                            >
                                                Ingresar
                                            </button>
                                        </div>  
                                    </Form>

                                )}    
                            </Formik>          

                        </div>
                    <div className="col-md"></div>
                </div>
            </div>
        )
    }  
//}
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {situacionSlice} from '../../store/slices/situacion';
import {convertFecha} from '../../utils/convertFechas';
import {useForm} from '../../hooks/useSituacion';
import Cookies from 'universal-cookie';
import { urlApi } from '../../utils/api';
const cookies = new Cookies(); 
const axios = require('axios');


export default function () {
 
  useEffect(() => {  getSituaciones(); },[]);
  const {result} = useSelector((state) => state.body);

  const {editaSituacion,situacionList,togleIcon}  = useSelector((state) => state.situacion);
  const {nivel}  = useSelector((state) => state.body);
  const dispatch = useDispatch();
  
  //hooks locales
  const { 
      onInputChange,
      setloading,
      loading,

      setNuevoCertificado,
      nuevoCertificado,    

      setidSituacion,
      idSituacion,

      setmsjSituacion,
      msjSituacion,

      setmsjOkSituacion,
      msjOkSituacion,

      setFormState,

      fechaDesde, 
      fechaHasta,
      fechaPresentacion,
      cantidadDias,
      medicoTratante,
      articulo,
      inciso,
      telefono,
      diagnostico,
      observaciones

  } = useForm({
      fechaDesde        : '', 
      fechaHasta        : '',
      fechaPresentacion : '',
      cantidadDias      : 0 ,
      medicoTratante    : '',
      articulo          : 0 ,
      inciso            : 0 ,
      telefono          : '',
      diagnostico       : '',
      observaciones     : ''
  });

  const nuevoCertControlMsj = () =>{
    setmsjSituacion('');
    setNuevoCertificado(true)
  }

  const getSituaciones = () => {

    setloading(true);
   
    axios.get(`${urlApi}/rrhh/situacionList/${result[0].idAgente}`,{params:{dni:cookies.get('dataDni')}})

      .then(resSituacionList => {

        let fechaDesdeStr        = '';
        let fechaHastaStr        = '';
        let fechaPresentacionStr = '';
        let listSituacionTemp    = [];

        resSituacionList.data.sort((a,b)=>{
            if (a.fechaDesde < b.fechaDesde){ return -1 }
            if (a.fechaDesde > b.fechaDesde){ return  1 }
            return 0;
          }
        )  

        resSituacionList.data.map(situacion => {
          (situacion.fechaDesde.includes('1969')||situacion.fechaDesde.includes('1970'))?fechaDesdeStr='':fechaDesdeStr=situacion.fechaDesde;
          (situacion.fechaHasta.includes('1969')||situacion.fechaHasta.includes('1970'))?fechaHastaStr='':fechaHastaStr=situacion.fechaHasta;
          (situacion.fechaPresentacion.includes('1969')||situacion.fechaPresentacion.includes('1970'))?fechaPresentacionStr='':fechaPresentacionStr=situacion.fechaPresentacion;

          var options = { year:'numeric', month:'long', day:'numeric', timeZone:'America/Argentina/Buenos_Aires'};
        
          if(fechaDesdeStr!==''){
            let fechaDesdeFormat = new Date(fechaDesdeStr);
            fechaDesdeFormat.setMinutes(fechaDesdeFormat.getMinutes() + fechaDesdeFormat.getTimezoneOffset())//compensacion diferencia de dia
            fechaDesdeStr=fechaDesdeFormat.toLocaleDateString('es-AR',options);  
          }
          if(fechaHastaStr!==''){
            let fechaHastaFormat = new Date(fechaHastaStr);
            fechaHastaFormat.setMinutes(fechaHastaFormat.getMinutes() + fechaHastaFormat.getTimezoneOffset())//compensacion diferencia de dia
            fechaHastaStr=fechaHastaFormat.toLocaleDateString('es-AR',options);
          }
          if(fechaPresentacionStr!==''){
            let fechaPresentacionFormat = new Date(fechaPresentacionStr);
            fechaPresentacionFormat.setMinutes(fechaPresentacionFormat.getMinutes() + fechaPresentacionFormat.getTimezoneOffset())//compensacion diferencia de dia
            fechaPresentacionStr=fechaPresentacionFormat.toLocaleDateString('es-AR',options);
          }



          situacion.fechaDesde=fechaDesdeStr;
          situacion.fechaHasta=fechaHastaStr;
          situacion.fechaPresentacion=fechaPresentacionStr;

          listSituacionTemp.push(situacion);

        })

        dispatch(situacionSlice.actions.set_situacion(listSituacionTemp.reverse()));       
        setloading(false);
        dispatch(situacionSlice.actions.set_editaSituacion(false));

      })   
      .catch(function (error) {
        setmsjSituacion('ERROR!: '+error)  
      });
  }

  const submitFormSituacion = ()=>{

    if( (fechaDesde==='') || (fechaHasta==='') || (fechaPresentacion==='') || (cantidadDias===0) ){
      setmsjSituacion('Debe completar los datos obligatorios (*)');
      setloading(false);
    }else{
      setloading(true);

      let fechaDesdeFormat = new Date(fechaDesde+'T00:00:00');
      let fechaHastaFormat = new Date(fechaHasta+'T00:00:00');
      let fechaPresentacionFormat = new Date(fechaPresentacion+'T00:00:00');
      
    
      axios({
        method: 'post',
        url: urlApi+'/rrhh/situacion',
        params:{dni:cookies.get('dataDni')},
        data: {
          "idSituacion"       : 0,
          "fechaDesde"        : fechaDesdeFormat,
          "fechaHasta"        : fechaHastaFormat,
          "fechaPresentacion" : fechaPresentacionFormat,
          "cantidadDias"      : cantidadDias,
          "medicoTratante"    : medicoTratante,
          "articulo"          : articulo,
          "inciso"            : inciso,
          "telefono"          : telefono,
          "diagnostico"       : diagnostico,
          "observaciones"     : observaciones,
          "idAgente"          : result[0].idAgente 
        }
      })
      .then(function () {
        setmsjSituacion('El certificado se agregó correctamente');
        terminarEdicionSituacion();
        getSituaciones();
      })
      .catch(function (error) {
        console.log('-->>',error);
      });
    }
  }

  const selectSituacion = (situacion,accion)=>{

      setmsjSituacion('');
      setNuevoCertificado(false);

      if(accion==='editar'){
        dispatch(situacionSlice.actions.set_editaSituacion(true));
        setidSituacion(parseInt(situacion.idSituacion));
      }else if(accion==='ver'){
        dispatch(situacionSlice.actions.set_editaSituacion(false));
        setidSituacion(parseInt(situacion.idSituacion));
      }

      setFormState({
        fechaDesde        : convertFecha(situacion.fechaDesde),
        fechaHasta        : convertFecha(situacion.fechaHasta),
        fechaPresentacion : convertFecha(situacion.fechaPresentacion),
        cantidadDias      : situacion.cantidadDias,
        medicoTratante    : situacion.medicoTratante,
        articulo          : situacion.articulo,
        inciso            : situacion.inciso,
        telefono          : situacion.telefono,
        diagnostico       : situacion.diagnostico,
        observaciones     : situacion.observaciones,
      })

  }

  const borrarSituacion = (situacion) =>{

      setmsjSituacion('');

      if (window.confirm('Confirma borrar el certificado N° '+situacion.idSituacion+' ?')) {
        setloading(true);
        axios({
          method: 'delete',
          url: urlApi+'/rrhh/situacion/'+situacion.idSituacion,
          params:{dni:cookies.get('dataDni')},
        })
        .then(function () {
          getSituaciones();
        })
        .catch(function (error) {
            console.log('-->>',error);
        });
      
      }
  }

  const terminarEdicionSituacion = () =>{
      setFormState({
          fechaDesde        : '', 
          fechaHasta        : '',
          fechaPresentacion : '',
          cantidadDias      : 0 ,
          medicoTratante    : '',
          articulo          : 0 ,
          inciso            : 0 ,
          telefono          : '',
          diagnostico       : '',
          observaciones     : ''
      });
      dispatch(situacionSlice.actions.set_editaSituacion(false));
  }

  const guardarEdicionSituacion = ()=>{

      setmsjSituacion('');

      if( (fechaDesde==='') && ((cantidadDias!==0)&&(fechaHasta!=='')) ){
          setmsjSituacion('Debe completar la fecha de inciio de la licencia médica');
      }else{
          let fechaDesdeFormat = new Date(fechaDesde+'T00:00:00');
          let fechaHastaFormat = new Date(fechaHasta+'T00:00:00');
          let fechaPresentacionFormat = new Date(fechaPresentacion+'T00:00:00');

          setloading(true);
          axios({
              method: 'put',
              url: urlApi+'/rrhh/situacion/'+idSituacion,
              params:{dni:cookies.get('dataDni')},
              data: {
             
                "fechaDesde"        : fechaDesdeFormat,
                "fechaHasta"        : fechaHastaFormat,
                "fechaPresentacion" : fechaPresentacionFormat,
                "cantidadDias"      : cantidadDias,
                "medicoTratante"    : medicoTratante,
                "articulo"          : articulo,
                "inciso"            : inciso,
                "telefono"          : telefono,
                "diagnostico"       : diagnostico,
                "observaciones"     : observaciones,
                "idAgente"          : result[0].idAgente 
              }
          })
          .then(function () {
            getSituaciones();
            setmsjSituacion("La situacion N° "+idSituacion+" se edito correctamente");
          })
          .catch(function (error) {
              console.log('-->>',error);
          });
      } 
  }

  const fLoading = ()=>{
    return( 
      <div className="spinner-border text-info" role="status" style={{'marginTop':15}}>
        <span className="visually-hidden">Loading...</span>
      </div>
    )
  }

  return (
    <>
      <div className="row p-1 border-bottom">
      
        <div className="col-md-12 p-2">

          {
            ((nivel===0)||(nivel===1))&&

            loading
            ?
              fLoading()
            :
              <button 
                type="button" 
                class="btn btn-success"
                data-bs-toggle="modal" 
                data-bs-target="#certificadoModal"
                onClick={()=>{ nuevoCertControlMsj() }}
              >
                Agregar Nuevo Certificado
              </button>
          }
        </div>
      </div>

      <div className="row p-1 border-bottom">
        <div className="col-md-12">
          <div className="p-1">Historial Certificados:</div>
          <table className="table table-sm table-striped table-success table-bordered">
            <thead>
              <tr>
                <th scope="col">id</th>
                <th scope="col">Desde</th>
                <th scope="col">Hasta</th>
                <th scope="col">Presentación</th>
                <th scope="col">Cantidad de dias</th>
                <th scope="col">Médico</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
    
            <tbody>
              {

                
                

                
                situacionList.map(situacion => (
                  <tr key={situacion.idSituacion}>
                    <td>{situacion.idSituacion}</td>
                    <td>{situacion.fechaDesde}</td>
                    <td>{situacion.fechaHasta}</td>
                    <td>{situacion.fechaPresentacion}</td>
                    <td>{situacion.cantidadDias}</td>
                    <td>{situacion.medicoTratante}</td>
                    <td>
                      <div className='row'>
                        <div className='col' style={{padding:'1px'}}>
                          <button 
                            type="button" 
                            className="btn btn-success btn-sm"
                            data-bs-toggle="modal" 
                            data-bs-target="#certificadoModal"
                            onClick={()=>selectSituacion(situacion,'ver')}
                          >
                            ver
                          </button>
                        </div>
                        {
                        ((nivel===0)||(nivel===1))
                        ? <> 
                            <div className='col' style={{padding:'1px'}}>
                              <button 
                                type="button" 
                                className="btn btn-danger btn-sm" 
                                data-bs-toggle="modal" 
                                data-bs-target="#certificadoModal"
                                onClick={()=>selectSituacion(situacion,'editar')}
                              >
                                editar
                              </button>
                            </div>
                            <div className='col' style={{padding:'1px'}}>
                              <button 
                                type="button" 
                                className="btn btn-danger btn-sm"
                                onClick={()=>borrarSituacion(situacion)}
                              >
                                borrar
                              </button>
                            </div>    
                          </>
                        :
                          null
                        }
                      </div>
                    </td>
                  </tr>
                ))
                
                
              }
            </tbody>
          </table>
        </div>
      </div>




      {/*Modal*/}
      <div class="modal modal-xl fade" id="certificadoModal" tabindex="-1" aria-labelledby="certificadoModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="certificadoModalLabel">
                { 
                  nuevoCertificado
                  ?
                    <p style={{'color':'red'}}>Vas a agregar un nuevo certificado</p>
                  :

                    editaSituacion 
                    ? 
                      <p style={{'color':'red'}}>Vas a editar el certificado N° {idSituacion}</p>
                    :
                      <p style={{'color':'green'}}>Información certificado N° {idSituacion}</p>
                }
              </h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form>
            <div class="modal-body">
              <div className="card card-body">
                <div className="row mt-2">

                  <div className="col-md-6">
                    <div className="input-group input-group-sm">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Desde*</span>
                      <input 
                        type="date" 
                        name="fechaDesde"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={fechaDesde}
                        onChange={onInputChange}
                        required
                      />
                    </div> 
                  </div>

                  <div className="col-md-6">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Hasta*</span>
                      <input 
                        type="date" 
                        name="fechaHasta"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={fechaHasta}
                        onChange={onInputChange}
                        required
                      />
                    </div> 
                  </div>
                </div>  

                <div className="row mt-2">

                   <div className="col-md-4">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Fecha Presentación*</span>
                      <input 
                        type="date" 
                        name="fechaPresentacion"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={fechaPresentacion}
                        onChange={onInputChange}
                        required
                      />
                    </div> 
                  </div>

                  <div className="col-md-4">
                    <div className="input-group input-group-sm">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Cantidad de Dias*</span>
                      <input 
                        type="number" 
                        name="cantidadDias"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={cantidadDias}
                        onChange={onInputChange}
                        required
                      />
                    </div> 
                  </div>

                  <div className="col-md-4">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Médico Tratante</span>
                      <input 
                        type="text"
                        name="medicoTratante"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={medicoTratante}
                        onChange={onInputChange}
                      />
                    </div> 
                  </div>
                </div>      

                <div className="row mt-2">

                   <div className="col-md-4">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Artículo</span>
                      <input 
                        type="text" 
                        name="articulo"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={articulo}
                        onChange={onInputChange}
                      />
                    </div> 
                  </div>

                  <div className="col-md-4">
                    <div className="input-group input-group-sm">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Inciso</span>
                      <input 
                        type="text" 
                        name="inciso"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={inciso}
                        onChange={onInputChange}
                      />
                    </div> 
                  </div>

                  <div className="col-md-4">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Teléfono</span>
                      <input 
                        type="text"
                        name="telefono"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={telefono}
                        onChange={onInputChange}
                      />
                    </div> 
                  </div>
                </div>     

                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Diagnóstico</span>
                      <textarea 
                        type="textarea" 
                        name="diagnostico"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={diagnostico}
                        onChange={onInputChange}
                      />
                    </div> 
                  </div>
                </div>
                
                <div className="row mt-2">
                  <div className="col-md-12">
                    <div className="input-group input-group-sm mb-3">
                      <span className="input-group-text" id="inputGroup-sizing-sm">Observaciones</span>
                      <textarea 
                        type="textarea" 
                        name="observaciones"
                        className="form-control" 
                        aria-label="Sizing example input" 
                        aria-describedby="inputGroup-sizing-sm"
                        value={observaciones}
                        onChange={onInputChange}
                      />
                    </div> 
                  </div>
                </div>
               
              </div>
            </div>
            <div class="modal-footer">
              {msjSituacion.includes('corre') ? <h6 style={{'color':'green'}}>{msjSituacion}</h6> : <h6 style={{'color':'red'}}>{msjSituacion}</h6>}
              {
                ((nivel===0)||(nivel===1))&&       

                  nuevoCertificado
                  
                  ?
                    loading
                    ?
                      fLoading()
                    :
                      <>
                        <button      
                          type="button" 
                          class="btn btn-primary"
                          onClick={()=>submitFormSituacion()}
                          style={{'marginRight':'2px'}}
                        >
                          Agregar
                        </button>

                        <button
                          onClick={()=>terminarEdicionSituacion()}
                          type="button" 
                          class="btn btn-secondary" 
                          data-bs-dismiss="modal"
                        >
                          Cerrar
                        </button>
                      </>
                  :

                    editaSituacion

                    ?
                      loading
                      ?
                        fLoading()
                      :
                        <div>
                          <button      
                            type="button" 
                            class="btn btn-primary"
                            onClick={()=>guardarEdicionSituacion()}
                            style={{'marginRight':'2px'}}
                          >
                            Guardar Cambios
                          </button>

                          <button
                            type="button" 
                            class="btn btn-secondary" 
                            data-bs-dismiss="modal"
                            onClick={()=>terminarEdicionSituacion()}
                          >
                            Cerrar
                          </button>
                        </div>
                      
                    : (nivel===0)&&

                      loading
                      ?
                        fLoading()
                      :  
                        <>
                          <button
                            onClick={()=>terminarEdicionSituacion()}
                            type="button" 
                            class="btn btn-secondary" 
                            data-bs-dismiss="modal"
                          >
                            Cerrar
                          </button>
                        </>
              }
            </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
import React from 'react';
import {Routes,Route} from 'react-router-dom';
import Body from '../components/Body';
import Login from '../pages/Login';
 
export const AppRouter = () => {
  return (
    <>
        <Routes>
            <Route path="/" element={<Login/>}/>
            <Route path="body" element={<Body/>}/>      
        </Routes>
    </>
  )
}

import { useState } from 'react';

export const useForm = ( initialForm = {} ) => {
  
    const [ formState, setFormState ]            = useState( initialForm );
    const [togleIcon, setTogleIcon]              = useState(false);
    const [idSituacion, setIdSituacion]          = useState(0);  
    const [msjSituacion,setMsjSituacion]         = useState('');
    const [msjOkSituacion,setMsjOkSituacion]     = useState('');
    const [loading,setLoading]                   = useState('');
    const [nuevoCertificado,setNuevoCertificado] = useState(false);


    const onInputChange = ({ target }) => {
        const { name, value } = target;

        setFormState({
            ...formState,
            [ name ]: value
        });
    }

    const settogleIcon      = value => setTogleIcon(value);
    const setidSituacion    = value => setIdSituacion(value);
    const setmsjSituacion   = value => setMsjSituacion(value);
    const setmsjOkSituacion = value => setMsjOkSituacion(value);

    const setloading  = value => setLoading(value);
    const onResetForm = () => setFormState( initialForm );

    return {
        ...formState,

        settogleIcon,
        togleIcon,

        setidSituacion,
        idSituacion,

        setmsjSituacion,
        msjSituacion,

        setmsjOkSituacion,
        msjOkSituacion,

        setloading,
        loading,

        setNuevoCertificado,
        nuevoCertificado,    

        setFormState,    

        onInputChange,
        onResetForm
    }
}
import { useState, useEffect } from "react";
import Situacion from './Situacion';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Cookies from 'universal-cookie';
import { useSelector, useDispatch } from 'react-redux';
import * as dispsBody from '../store/slices/body';
import { urlApi } from "../utils/api";
const axios = require('axios');
const cookies = new Cookies(); 

export default function ({accion}){
  
  const dispatch = useDispatch();
  const dataAgente = useSelector((state) => state.body.result[0]);
  const [verSituacion, setverSituacion]         = useState(false);
  const [noEditable, setnoEditable]             = useState(true);
  const [loading, setLoading]                   = useState(false);
 
  useEffect(() => {

   
    
    if(accion==='edit'){setnoEditable(false)}
  },[]);
 
  const getFnacimiento=()=>{
    if(dataAgente.Fnacimiento!==null){
      let fnacimientoTemp = null;
      let fnacimientoFormat = '';
      fnacimientoTemp = new Date(dataAgente.Fnacimiento);
      fnacimientoTemp.setMinutes(fnacimientoTemp.getMinutes() + fnacimientoTemp.getTimezoneOffset())//compensacion diferencia de dia
      fnacimientoFormat = fnacimientoTemp.getFullYear() + '-' + String(fnacimientoTemp.getMonth() + 1).padStart(2, '0') + '-' + String(fnacimientoTemp.getDate()).padStart(2, '0');
      return(fnacimientoFormat);
    }else{
      return(null)
    }
  }

  const getIngreso=()=>{
    if(dataAgente.Ingreso!==null){
      let ingresoTemp = null;
      let ingresoFormat = '';
      ingresoTemp = new Date(dataAgente.Ingreso);
      ingresoTemp.setMinutes(ingresoTemp.getMinutes() + ingresoTemp.getTimezoneOffset())//compensacion diferencia de dia
      ingresoFormat = ingresoTemp.getFullYear() + '-' + String(ingresoTemp.getMonth() + 1).padStart(2, '0') + '-' + String(ingresoTemp.getDate()).padStart(2, '0');
      return(ingresoFormat);
    }else{
      return null
    }
  }

  const getAntiguedad=()=>{
    if(dataAgente.Ingreso!==null){
      let fechaActual  = new Date().getFullYear();
      let fechaIngreso = null;
      if(dataAgente.Ingreso.includes('00:00')){
        fechaIngreso = new Date(dataAgente.Ingreso);
        fechaIngreso.setMinutes(fechaIngreso.getMinutes() + fechaIngreso.getTimezoneOffset())//compensacion diferencia de dia
        fechaIngreso = new Date(fechaIngreso).getFullYear();
      }else{
        fechaIngreso = new Date(dataAgente.Ingreso+'T00:00:00').getFullYear()
      }
      return(fechaActual-fechaIngreso + ' ' + 'años');
    }else{
      return('completar ingreso!'); 
    }
  }

  const submitForm = async (valores)=>{
   
    setLoading(true);

    await axios({
      method: 'put',
      url: `${urlApi}/rrhh/${dataAgente.idAgente}`,
      params:{dni:cookies.get('dataDni')},
      data: {
        "Situacion"         : valores.situacion,
        "Legajo"            : valores.legajo,
        "Secretaria"        : valores.secretaria,
        "sector"            : valores.sector,
        "Categoria"         : valores.categoria,
        "Ingreso"           : valores.ingreso,
        "Fnacimiento"       : valores.fnacimiento, 
        "AyP"               : valores.apellido,
        "Dni"               : valores.dni,
        "Cuil"              : valores.cuil, 
        "SubroganciaReem"   : valores.subReem,
        "PuestoTrabajo"     : valores.puestoTrabajo,
        "NivelInstruccionS" : valores.ninstruccion,
        "NivelInstruccion"  : valores.ninstruccionO,
        "MesIngreso"        : valores.mesIngreso,
        "AnioIngreso"       : valores.anioIngreso,
        "Domicilio"         : valores.domicilio  
      }
    })
    .then(function () {
      setLoading(false);
      alert('El agente se editó correctamente')
      getAntiguedad();
      setverSituacion(true);
      
    })
    .catch(function (error) {
      alert('ERROR2: ',error)  
    });
  }

  const volver = ()=>{
    dispatch(dispsBody.set_volver(true));
    dispatch(dispsBody.set_verEditVerAgente(false));
    dispatch(dispsBody.set_verSearchAgente(true));
  }

  return (
    <>
      <Formik
        initialValues={{
          apellido     :dataAgente.AyP,
          nombre       :dataAgente.AyP,
          dni          :dataAgente.Dni,
          legajo       :dataAgente.Legajo,
          cuil         :dataAgente.Cuil,
          secretaria   :dataAgente.Secretaria,
          sector       :dataAgente.sector,
          categoria    :dataAgente.Categoria,
          ingreso      :getIngreso(),
          antiguedad   :getAntiguedad(),
          fnacimiento  :getFnacimiento(),
          domicilio    :dataAgente.Domicilio,
          anioIngreso  :dataAgente.AnioIngreso,
          mesIngreso   :dataAgente.MesINgreso,
          puestoTrabajo:dataAgente.PuestoTrabajo,
          subReem      :dataAgente.SubroganciaReem,
          situacion    :dataAgente.Situacion,
          ninstruccion :dataAgente.NivelInstruccionS,
          ninstruccionO:dataAgente.NivelInstruccion
        }}
        validate={(valores) => {
          let errores = {};
          return errores;
        }}
        onSubmit={(valores) => {submitForm(valores);}}
      >
      {( {errors} ) => (
        <Form>

          {
            accion==='ver'
            ?
            <>
              <div className="row p-2">
                <div className="col-md-1">
                  <button type="button" className="btn btn-primary btn-sm" onClick={()=>volver()}>volver</button>
                </div>
                <div className="col-md-11">
                  <div className="p-1 border-bottom">Ver Empleado</div>
                </div>
              </div>
            </>  
            :
            <>
              <div className="row p-2">
                <div className="col-md-1">
                  <button type="button" className="btn btn-primary btn-sm" onClick={()=>volver()}>volver</button>
                </div>
                <div className="col-md-11">
                  <div className="p-1 border-bottom">Editar Empleado</div>
                </div>
              </div>
            </>  
            
          }
          <div className="row p-2">
            <div className="col-md-6">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">Apellido</span>
                <Field 
                  className="form-control" 
                  type="text" 
                  name="apellido"
                  id="apellido"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={noEditable}  
                />          
              </div>
            </div>

            <div className="col-md-6">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">Nombre</span>
                <Field 
                  className="form-control" 
                  type="text" 
                  name="nombre"
                  id="nombre"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={noEditable} 
                />
              </div> 
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-4">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">Dni</span>
                <Field 
                  className="form-control"                 
                  type="number"
                  name="dni"
                  id="dni"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={noEditable} 
                />
              </div> 
            </div>

            <div className="col-md-4">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">Legajo</span>
                <Field 
                  className="form-control"
                  type="number"
                  name="legajo"
                  id="legajo"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={noEditable}   
                />
              </div> 
            </div>

            <div className="col-md-4">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">Antiguedad</span>
                <Field 
                  className="form-control"
                  type="text"
                  name="antiguedad"
                  id="antiguedad"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={true} 
                />
              </div> 
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-4">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">Secretaría</span>
                <Field 
                  className="form-control"
                  type="text" 
                  name="secretaria"
                  id="secretaria"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={noEditable} 
                />
              </div> 
            </div>

            <div className="col-md-4">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">Sector</span>
                <Field 
                  className="form-control"
                  type="text" 
                  name="sector"
                  id="sector"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={noEditable} 
                />
              </div> 
            </div>

            <div className="col-md-4">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">Ingreso</span>
                <Field 
                  className="form-control"
                  type="date" 
                  name="ingreso"
                  id="ingreso"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={noEditable} 
                />
              </div> 
            </div>
          </div>

          <div className="row p-2">
            <div className="col-md-4">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">Fecha Nacimiento</span>
                <Field
                  className="form-control"
                  type="date" 
                  name="fnacimiento"
                  id="fnacimiento"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={noEditable} 
                />
              </div> 
            </div>

            <div className="col-md-4">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">Domicilio</span>
                <Field
                  className="form-control"
                  type="text" 
                  name="domicilio"
                  id="domicilio"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={noEditable} 
                />
              </div> 
            </div>

               <div className="col-md-4">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">Año de Ingreso</span>
                <Field
                  className="form-control"
                  type="text"
                  name="anioIngreso"
                  id="anioIngreso"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={noEditable} 
                />
              </div> 
            </div>
        
          </div>

       

          <div className="row p-2 mb-4">
            <div className="col-md-3">
              <div className="input-group input-group-sm mb-3">
                <span className="input-group-text" id="inputGroup-sizing-sm">**Situación**</span>
                <Field
                  className="form-control"  
                  type="text" 
                  name="situacion"
                  id="situacion"
                  aria-label="Sizing example input" 
                  aria-describedby="inputGroup-sizing-sm"
                  disabled={noEditable} 
                />
              </div> 
            </div>

         
            <div className="col-md-3">
              <div 
                className="input-group input-group-sm mb-3"            
              >
                <label className="input-group-text" htmlFor="inputGroupSelect01">Nivel de Instrucción</label>
                <Field 
                  className="form-select" 
                  as="select"
                  name="ninstruccion"
                  id="ninstruccion"
                  disabled={noEditable}
                >
                  <option defaultValue> Elegir...              </option>
                  <option value="primario"> Primario           </option>
                  <option value="secundario"> Secundario       </option>
                  <option value="terciario"> Terciario         </option>
                  <option value="universitario"> Universitario </option>
                  <option value="doctorado"> Doctorado         </option>
                </Field>
              </div>
            </div>

            <div className="col-md-6">
            {/*ACOMODAR*/}
            </div>

          </div>

          <div className="row p-1 border-bottom">  
            <div className="col-md-12 p-2">
              {
                loading
                ?
                  <div className="spinner-border text-info" role="status" style={{'marginTop':15}}>
                      <span className="visually-hidden">Loading...</span>
                  </div>
                :
                  !noEditable &&
                    <button 
                      type="submit"
                      className="btn btn-sm shadow mb-5 rounded" 
                      style={{'backgroundColor':'#F3CDA9'}}
                    >
                      Guardar Cambios
                    </button>
              }
            </div>
          </div>
        </Form>
      )}
      </Formik>

      <div className="row p-1 border-bottom">
        <div className="col-md-12 p-2">
          <button 
            className="btn btn-sm shadow mb-1 rounded" 
            style={{'backgroundColor':'#F3CDA9'}}
            onClick={()=>setverSituacion(true)}
          >
            Ver Historial
          </button>
        </div>
      </div>


      {verSituacion && <Situacion/>}
      <div className="row p-1 border-bottom" style={{'height':'15rem'}}></div>
    </>
  )
}

import React from 'react'
import { useSelector } from 'react-redux';

export const SearchAgente = ({editVerAgente}) => {

  const {nivel,result} = useSelector((state) => state.body);

  let cantidad = 0;
  
  if(!((result.length===1)&&(result[0]===''))){cantidad = result.length;}

  return(
    <>
      <hr/>
      <h5>{cantidad} Resultados</h5>
      <hr/>
      <table className="table table-sm table-striped table-success table-bordered">
        <thead>
          <tr>
            <th scope="col">Legajo</th>
            <th scope="col">Dni</th>
            <th scope="col">Secretaria</th>
            <th scope="col">Apellido y Nombre</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {result.map(agente => (
            
            <tr key={agente.idAgente}>
              <td>{agente.Legajo}</td>
              <td>{agente.Dni}</td>
              <td>{agente.Secretaria}</td>
              <td>{agente.AyP}</td>
              <td>
                <div className='row'>

                  {
                    (cantidad>0)&&
                    <div className='col' style={{padding:'1px'}}>
                      <button 
                        type="button" 
                        className="btn btn-success btn-sm"
                        onClick={()=>editVerAgente(agente.Legajo,'ver')}
                      >
                        ver
                      </button>
                    </div>
                  
                  }

                  {
                    (cantidad>0)&&(
                      ((nivel===0)||(nivel===1))&&
                      <div className='col' style={{padding:'1px'}}>
                        <button 
                          type="button" 
                          className="btn btn-danger btn-sm"
                          onClick={()=>editVerAgente(agente.Legajo,'edit')}
                        >
                          editar
                        </button>
                      </div>
                    )  
                  }

                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )  
}

import { useState } from "react";
import Cookies from 'universal-cookie';
import { urlApi } from "../utils/api";
const axios = require('axios');
const cookies = new Cookies(); 

export default function () {

  const [apellido, setApellido]           = useState('');
  const [nombre, setNombre]               = useState('');
  const [dni, setDni]                     = useState('');
  const [legajo, setLegajo]               = useState('');
  const [cuil, setCuil]                   = useState('');
  const [secretaria, setSecretaria]       = useState('');
  const [categoria, setCategoria]         = useState('');
  const [ingreso, setIngreso]             = useState(null);
  const [fnacimiento, setFnacimiento]     = useState(null);
  //const [cargoFuncion, setCargoFuncion]  = useState('');
  const [domicilio, setDomicilio]         = useState('');
  const [anioIngreso, setAnioIngreso]     = useState('');
  const [mesIngreso, setMesIngreso]       = useState('');
  const [puestoTrabajo,setPuestoTrabajo]  = useState('');
  const [subReem, setSubReem]             = useState('');
  const [situacion,setSituacion]          = useState('');
  const [ninstruccion, setNinstruccion]   = useState('');
  const [ninstruccionO, setNinstruccionO] = useState('');
  const [verSituacion, setverSituacion]   = useState(false);
  const [msj, setMsj] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInput = (e,setEstado)=>{
    setEstado(e.target.value);
    setMsj('');
  }

  const submitForm = (e)=>{

    e.preventDefault();
    setLoading(true);
    setverSituacion(false);
    axios({
      method: 'post',
      url: `${urlApi}/rrhh`,
      params:{dni:cookies.get('dataDni')},
      data: {
        "idAgente"          : 0,
        "Situacion"         : situacion,
        "Legajo"            : legajo,
        "Secretaria"        : secretaria,
        "Categoria"         : categoria,
        "Ingreso"           : ingreso,
        "Fnacimiento"       : fnacimiento,
        "AyP"               : apellido,
        "Dni"               : dni,
        "Cuil"              : cuil, 
        "SubroganciaReem"   : subReem,
        "PuestoTrabajo"     : puestoTrabajo,
        "NivelInstruccionS" : ninstruccion,
        "NivelInstruccion"  : ninstruccionO,
        "MesIngreso"        : mesIngreso,
        "AnioIngreso"       : anioIngreso,
        "Domicilio"         : domicilio 
      }
    })
    .then(function () {
      setMsj('El agente fué dado de alta correctamente');
      setApellido('');
      setNombre('');
      setDni('');
      setLegajo('');
      setCuil('');
      setSecretaria('');
      setCategoria('');
      setIngreso(null);
      setFnacimiento(null);
      setDomicilio('');
      setAnioIngreso('');
      setMesIngreso('');
      setPuestoTrabajo('');
      setSubReem('');
      setSituacion('');
      setNinstruccion('');
      setNinstruccionO('');
      setverSituacion(true);
      setLoading(false);
    })
    .catch(function (error) {
      setLoading(false);
      console.log('-->>',error);
    });
    
  }

  return (
    <form onSubmit={ submitForm }>

      <div className="p-1 border-bottom">Alta Empleado</div> 

      <div className="row p-2">
        <div className="col-md-6">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text" id="inputGroup-sizing-sm">Apellido</span>
            <input 
              type="text" 
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={apellido}
              onChange={(e)=>{handleInput(e,setApellido)}}
             
              required
            />          
          </div>
        </div>

        <div className="col-md-6">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text" id="inputGroup-sizing-sm">Nombre</span>
            <input 
              type="text" 
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={nombre}
              onChange={(e)=>{handleInput(e,setNombre)}}
           
              required
            />
          </div> 
        </div>
      </div>

      <div className="row p-2">
        <div className="col-md-6">
          <div className="input-group input-group-sm mb-3">

            <span className="input-group-text" id="inputGroup-sizing-sm">Dni</span>
            <input 
              type="number" 
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={dni}
              onChange={(e)=>{handleInput(e,setDni)}}
        
              required
            />

          </div> 
        </div>

        <div className="col-md-6">
          <div className="input-group input-group-sm mb-3">

            <span className="input-group-text" id="inputGroup-sizing-sm">Legajo</span>
            <input
              type="number" 
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={legajo}
              onChange={(e)=>{handleInput(e,setLegajo)}}  
           
              required
            />

          </div> 
        </div>

       
      </div>



      <div className="row p-2">

        <div className="col-md-6">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text" id="inputGroup-sizing-sm">Secretaría</span>
            <input 
              type="text" 
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={secretaria}
              onChange={(e)=>{handleInput(e,setSecretaria)}}
            
            />
          </div> 
        </div>
        
      

        <div className="col-md-6">
          <div className="input-group input-group-sm mb-3">

            <span className="input-group-text" id="inputGroup-sizing-sm">Ingreso</span>
            <input 
              type="date" 
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={ingreso}
              onChange={(e)=>{handleInput(e,setIngreso)}}
    
            />

          </div> 
        </div>
        
      </div>


      <div className="row p-2">

        <div className="col-md-6">
          <div className="input-group input-group-sm mb-3">

            <span className="input-group-text" id="inputGroup-sizing-sm">Fecha Nacimiento</span>
            <input 
              type="date" 
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={fnacimiento}
              onChange={(e)=>{handleInput(e,setFnacimiento)}}

            />

          </div> 
        </div>

        <div className="col-md-6">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text" id="inputGroup-sizing-sm">Domicilio</span>
            <input 
              type="text" 
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={domicilio}
              onChange={(e)=>{handleInput(e,setDomicilio)}}

            />
          </div> 
        </div>

      </div>

      <div className="row p-2">

        <div className="col-md-6">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text" id="inputGroup-sizing-sm">Año de Ingreso</span>
            <input 
              type="text" 
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={anioIngreso}
              onChange={(e)=>{handleInput(e,setAnioIngreso)}}
   
            />
          </div> 
        </div>

        <div className="col-md-6">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text" id="inputGroup-sizing-sm">Mes de Ingreso</span>
            <input 
              type="text" 
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={mesIngreso}
              onChange={(e)=>{handleInput(e,setMesIngreso)}}
     
            />
          </div> 
        </div>

      </div>

      <div className="row p-2 mb-4">

        <div className="col-md-6">
          <div className="input-group input-group-sm mb-3">
            <span className="input-group-text" id="inputGroup-sizing-sm">**Situación**</span>
            <input 
              type="text" 
              className="form-control" 
              aria-label="Sizing example input" 
              aria-describedby="inputGroup-sizing-sm"
              value={situacion}
              onChange={(e)=>{handleInput(e,setSituacion)}}
       
            />
          </div> 
        </div>

        <div className="col-md-6">
          <div 
            className="input-group input-group-sm mb-3"
            value={ninstruccion}
            onChange={(e)=>{handleInput(e,setNinstruccion)}}
          >
            <label className="input-group-text" htmlFor="inputGroupSelect01">Nivel de Instrucción</label>
            <select className="form-select" id="inputGroupSelect01">
              <option defaultValue>Elegir...     </option>
              <option value="primario">Primario     </option>
              <option value="secundario">Secundario   </option>
              <option value="terciario">Terciario    </option>
              <option value="universitario">Universitario</option>
              <option value="doctorado">Doctorado    </option>
            </select>
          </div>
        </div>
      </div>


      <div className="row p-1 border-bottom">

        {
             loading
            ?
              <div className="spinner-border text-info" role="status" style={{'marginTop':15}}>
                  <span className="visually-hidden">Loading...</span>
              </div>
            :
              <p style={{color:'green'}}>{msj}</p>
        }
        
        <div className="col-md-12 p-2">
          <div>
            <button 
              type="submit"
              className="btn btn-sm shadow mb-5 rounded" 
              style={{'backgroundColor':'#F3CDA9'}}
            >
              Dar de Alta
            </button>
          </div>
        </div>
      </div>


      {/*verSituacion && <Situacion legajoAgente={legajo}/>*/}

      <div className="row p-1 border-bottom" style={{'height':'15rem'}}>
      </div>

    </form>
  )
}
